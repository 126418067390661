import React, { useEffect, useState } from "react";
import { menuItems } from "../../Constant/NavbarConstant";
import { Bars3BottomLeftIcon, ShoppingCartIcon } from "@heroicons/react/24/outline";
import { Link, useLocation, useNavigate } from "react-router";
import ButtonUI from "../../Common/Button";
import { useSelector } from "react-redux";
import SidePanel from "./SidePanel";
import axios from "axios";
import { getUUID } from "../../Services/Utlis/UUID";
import SearchProducts from "../SearchBar";
import useCategories from "../../hooks/useCategories";

const Navbar = () => {
  const guestId = getUUID(); // Retrieve UUID from localStorage
  const countNumber = useSelector((state) => state?.rootReducer?.countNumber);

  const [activeCategories, setActiveCategories] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    // If menuItems and descriptions exist, set default categories
    if (menuItems?.items?.length > 0 && menuItems.items[0]?.description?.length > 0) {
      const defaultCategories = menuItems.items[0].description[0].categories;
      setActiveCategories(defaultCategories); // Set default categories
    }
  }, [menuItems]);


  // Function to toggle the menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const [isScreenLarge, setIsScreenLarge] = useState(window.innerWidth > 768); // Assume 768px is the breakpoint for large screens

  // Handle window resizing
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMenuOpen(false); // Hide menu when screen size is large
        setIsScreenLarge(true);
      } else {
        setIsScreenLarge(false);
      }
    };

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [cartCount, setCartCount] = useState(() => {
    return Number(localStorage.getItem("cartCount")) || 0;
  }); // State for cart item count
  const navigate = useNavigate(); // Navigation hook

  // Function to update cart count
  const updateCartCount = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.get(`${apiUrl}/cart`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "Guest-ID": guestId, // Assuming guestId is obtained from context or state
        },
      });
      if (response.data?.cart) {
        setCartCount(response.data.cart.length); // Update cart count
      }
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };

  useEffect(() => {
    updateCartCount();
  }, []);
  const { categories } = useCategories();

  const [activeCategoryIndex, setActiveCategoryIndex] = useState(null);
  const [activeSubcategory, setActiveSubcategory] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  const [industriesData, setIndustriesData] = useState([]);

  // Toggle dropdown visibility
  useEffect(() => {
    const fetchIndustriesData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/industries`); // API endpoint to fetch all industries
        setIndustriesData(response.data);
      } catch (error) {
        console.error("Error fetching industries data:", error);
      }
    };

    fetchIndustriesData();
  }, []);

  // Handle button click
  const handleButtonClick = (buttonName) => {
    if (activeButton === buttonName) {
      // If the same button is clicked, toggle the dropdown
      setDropdownOpen(!dropdownOpen);
    } else {
      // If a different button is clicked, switch the content and ensure dropdown stays open
      setActiveButton(buttonName);
      setDropdownOpen(true);
    }
  };

  // Handle category click
  const handleCategoryClick = (index) => {
    setActiveCategoryIndex(index);
    setActiveSubcategory(null);
    setDropdownOpen(false);
  };
  const handleMouseLeave = () => {
    setDropdownOpen(true);
  };
  // Handle subcategory click
  const handleSubcategoryClick = (subcategory) => {
    setActiveSubcategory(subcategory);
    setDropdownOpen(false);
  };

  const activeCategory = categories[activeCategoryIndex];

  return (
    <>
      <header className="bg-white sticky top-0 z-50 shadow-sm  ">
        <div className={`container mx-auto flex items-center    px-4 h-20    justify-around `}>
          {/* Logo Section */}
          <div className="flex justify-around items-center gap-3">
            <div className={`flex items-center space-x-4 ${isMenuOpen ? "gap-36" : "gap-36"} `}>
              <Link to={"/"}>
                <img src="/logo.png" alt="Logo" style={{ width: "180px", height: "auto" }} className="object-contain" />
              </Link>
              <button className="lg:hidden md:hidden  text-orange-500 font-bold" onClick={toggleMenu}>
                {isMenuOpen ? (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 font-bold ">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                ) : (
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 font-bold">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 5.75h16.5M3.75 12h16.5m-16.5 6.25h16.5" />
                    </svg>

                    {/* <div className="  mb-5">
                      <SearchProducts />
                    </div> */}
                  </div>
                )}
              </button>
            </div>
            <nav className="hidden md:block">
              {/* Navbar Header */}
              <div className="flex items-center justify-between px-6 py-4 ">
                <div className="flex items-center space-x-8">
                  <button
                    className={`text-base font-semibold flex gap-2 items-center text-black ${activeButton === "allProducts" ? "bg-primary h-20 text-white p-3" : "hover:bg-secondary h-20  p-3"}`}
                    onClick={() => handleButtonClick("allProducts")}
                  >
                    <Bars3BottomLeftIcon aria-hidden="true" className="size-5 flex-none text-red-500" />
                    All Products
                  </button>
                  <button
                    className={`text-base font-semibold text-black flex items-center gap-2  ${activeButton === "shopByIndustry" ? "bg-primary h-20 text-white p-3" : "hover:bg-secondary h-20  p-3"}`}
                    onClick={() => handleButtonClick("shopByIndustry")}
                  >
                    {" "}
                    <Bars3BottomLeftIcon aria-hidden="true" className="size-5 flex-none text-red-500" />
                    Shop by industry
                  </button>
                </div>
              </div>

              {/* Dropdown */}
              {/* {dropdownOpen && categories && categories.length > 0 && ( */}
              {dropdownOpen && (activeButton === "shopByIndustry" ? industriesData.length > 0 : categories.length > 0) && (
                <div className=" absolute left-0 top-full w-full  bg-white border-t border-gray-200 shadow-lg" onMouseEnter={handleMouseLeave}>
                  <div
                    className="grid grid-cols-4 gap-4 p-6 overflow-auto  scrollbar-hidden "
                    style={{
                      maxHeight: "400px", // Set the maximum height
                      // overflowY: "auto", // Enable vertical scrolling
                      // scrollbarWidth: "none",
                    }}
                  >
                    {/* Column 1: Heading */}
                    <div>
                      <h2 className="text-lg font-bold text-gray-800">{activeButton === "allProducts" ? "All Products" : "Shop by Industry"}</h2>
                      <p className="text-sm text-gray-500 mt-2">Can’t find what you’re looking for? Try using the search bar above.</p>
                    </div>

                    {/* Column 2: Categories */}
                    <div className="border-l pr-4">
                      {activeButton === "shopByIndustry"
                        ? industriesData.map((industry, index) => (
                            <div
                              key={index}
                              className={`cursor-pointer p-2  flex items-center hover:text-primary  gap-2 ${activeCategoryIndex === index ? "hover:text-primary" : "text-black"}`}
                              onClick={() => handleCategoryClick(index)}
                              onMouseEnter={() => setActiveCategoryIndex(index)}
                            >
                              <Link to={`/industries/${industry?.slug}`}>
                                <span className="md:text-base text-sm">{industry.category}</span>
                              </Link>
                            </div>
                          ))
                        : categories?.map((item, index) => (
                            <div
                              key={index}
                              className={`cursor-pointer p-2  flex items-center hover:text-primary  gap-2 ${activeCategoryIndex === index ? "hover:text-primary" : "text-black"}`}
                              onClick={() => handleCategoryClick(index)}
                              onMouseEnter={() => setActiveCategoryIndex(index)}
                            >
                              <Link to={`/categories/${item?.category.replace(/ /g, "-")}`} className="flex items-center gap-2">
                                {" "}
                                <span>
                                  {" "}
                                  <img src={item?.image || "/assets/empty.png"} alt={`Category ${index}`} className="w-12  h-auto object-cover	 rounded-md" />
                                </span>
                                <span className="md:text-base text-sm">{item.category}</span>
                              </Link>
                            </div>
                          ))}
                    </div>

                    {/* Column 3: Subcategories */}
                    <div className="border-l pr-4">
                      {activeButton === "allProducts" && activeCategoryIndex !== null && activeCategory?.subcategories?.length > 0 ? (
                        activeCategory.subcategories.map((subcategory, index) => (
                          <div key={index} className={`cursor-pointer p-2 hover:text-primary ${index === 0 ? "mt-4" : ""}`} onClick={() => handleSubcategoryClick(subcategory)}>
                            <Link to={`/products/${activeCategory.category.replace(/ /g, "-")}/${subcategory.replace(/ /g, "-")}`} className="md:text-base text-sm ">
                              {subcategory}
                            </Link>
                          </div>
                        ))
                      ) : activeButton === "allProducts" ? (
                        <div className="text-gray-500 p-2">No subcategories available for this category.</div>
                      ) : null}
                    </div>

                    {/* Column 4: Images */}
                    <div className="border-l pl-4">
                      <div>
                        {" "}
                        <img src="/assets/Sunshine.jpg" alt={"name"} className="rounded-lg" width={"50%"} />
                        <button className="mt-3 bg-primary rounded-full p-2 min-w-28 text-white">Best Seller</button>
                      </div>
                      <div className="mt-5">
                        {" "}
                        <img src="/assets/MilkLab.jpg" alt={"name"} className="rounded-lg" width={"50%"} />
                        <button className="mt-3 bg-primary rounded-full p-2 min-w-28 text-white">Best Seller</button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </nav>
          </div>

          {/* Search and Contact Section */}
          <div className="flex items-center space-x-2">
            <div className="hidden lg:block">
              <SearchProducts />
            </div>
            {/* Contact Button */}
            <div className="hidden md:block">
              {/* Show the "Contact Us" button in the mobile menu */}
              <ButtonUI additionalClass="bg-primary text-white px-4 py-2 rounded-full hover:bg-primary " title="Contact Us" />
            </div>
            <div className="md:block hidden">
              {/* Cart Icon - Only shown on large screens */}
              <div className="relative cursor-pointer " onClick={() => navigate("/cart")}>
                <ShoppingCartIcon className="size-6 text-primary" />

                <div className="absolute top-0 right-0 w-4 h-3 bg-red-500 rounded-full text-white flex items-center justify-center text-xs"> {countNumber || 0}</div>
              </div>
            </div>
          </div>
        </div>
        {!isScreenLarge && isMenuOpen && <SidePanel onClose={() => setIsMenuOpen(false)} />}
      </header>
    </>
  );
};

export default Navbar;
