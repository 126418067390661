import { Routes, Route } from "react-router-dom";
import { Suspense } from "react";
import React from 'react';
import routes from "./route";
import FooterPage from "../Components/Footer";
import Navbar from "../Components/Navbar";
 import DynamicTitle from "../Services/Utlis/DynamicTitle";
const Routing = () => {
  return (
    <>
      {/* Persistent Header */}
      <Navbar />
      {/* Suspense for lazy-loaded components */}
      <Suspense
        fallback={
          <div className="text-center flex justify-center h-[500px]">
            <img src="/logo.png" alt="Logo" style={{ width: "180px", height: "auto" }} className="object-contain" />
          </div>
        }
      >
        <DynamicTitle />
        <Routes>
          {routes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Routes>
      </Suspense>
      <FooterPage />
    </>
  );
};

export default Routing;
