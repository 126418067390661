import React from "react";
import { Link } from "react-router";
import { FooterCategories, FooterContact, FooterSupport } from "../../Constant/Footer";
import FacebookIcon from "../Icon/Facebook";
import InstagramIcon from "../Icon/Instagram";
import LinkedInIcon from "../Icon/IinkedIn";
import ButtonUI from "../../Common/Button";
import useCategories from "../../hooks/useCategories";

const FooterPage = () => {
  const { categories, error } = useCategories();

  return (
    <footer className="bg-footer text-white py-8 containerMax md:px-20 px-8  ">
      <div className="border-b pb-10 grid md:grid-cols-2 grid-col-1 gap-3 pt-12">
        <Link to={"/"}>
          <img src="/logo.png" alt="Logo" style={{ width: "200px", height: "auto" }} className="object-contain" />
        </Link>
        <div>
          <h6 className="text-2xl font-bold text-white ">Subscribe to our newsletter</h6>
          <div className="mt-2 flex gap-3">
            <input
              id="email"
              name="email"
              type="email"
              required
              autoComplete="email"
              placeholder="Enter email address"
              className="block w-full rounded-full bg-footer border border-white px-3 py-3 text-base text-white outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-none"
            />
            {/* Button */}
            <ButtonUI title="Subscribe" additionalClass={` hover:outline hover:outline-1 hover:outline-white  hover:bg-black hover:text-white bg-primary text-white`} />
          </div>
        </div>
      </div>

      <div className="container mx-auto pt-10 pb-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 ">
          <div>
            <h3 className="font-bold text-3xl mb-4" style={{ opacity: 0.5 }}>
              Quick Links
            </h3>
            <ul className="space-y-2">
              {FooterCategories?.map((item, index) => (
                <li key={index}>
                  <Link to={item.link} className="text-sm hover:text-primary">
                    {item.name} {/* Assuming item has a 'name' property */}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="font-bold text-3xl mb-4" style={{ opacity: 0.5 }}>
              Categories
            </h3>
            <ul className="space-y-2">
              {}
              {categories?.map((item, index) => (
                <li key={index}>
                  <Link to={`/categories/${item.category}`} className="text-sm hover:text-primary">
                    {item.category} {/* Assuming item has a 'name' property */}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="font-bold text-3xl mb-4" style={{ opacity: 0.5 }}>
              Support
            </h3>
            <ul className="space-y-2">
              {FooterSupport?.map((item, index) => (
                <li key={index}>
                  <Link to={item.link} className="text-sm hover:text-primary">
                    {item.name} {/* Assuming item has a 'name' property */}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="font-bold text-3xl mb-4" style={{ opacity: 0.5 }}>
              Contact
            </h3>
            <ul className="space-y-2">
              {FooterContact?.map((item, index) => (
                <li key={index}>
                  <Link to={item.link} className="text-sm hover:text-primary">
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
            {/* Social Links */}
            <div className="mt-16">
              <h3 className="font-bold text-3xl mb-4" style={{ opacity: 0.5 }}>
                Connect with us
              </h3>
              <div className="mt-4 flex space-x-4">
                <Link to="https://www.facebook.com/promotiau" className="text-gray-300 hover:text-white" target="_blank">
                  <FacebookIcon color={"#fff"} />{" "}
                </Link>
                <Link to="https://www.instagram.com/promoti.au/" className="text-gray-300 hover:text-white" target="_blank">
                  <InstagramIcon color={"#fff"} />
                </Link>
                <Link to="https://www.linkedin.com/company/promoti/" className="text-gray-300 hover:text-white" target="_blank">
                  <LinkedInIcon color={"#fff"} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="border-t pt-8 grid md:grid-cols-2 grid-col-1 gap-3">
        <p style={{ opacity: 0.5 }}>
          Copyright 2024. <span className="text-primary">Promoti</span> . All Rights Reserved
        </p>
        <div className="flex justify-end">
          <img src="/assets/visa.png" />
          <img src="/assets/master.png" />
          <img src="/assets/amex.png" />
        </div>
      </div>
    </footer>
  );
};

export default FooterPage;
