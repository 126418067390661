export const products = [
  { id: 0, name: "All Products", description: "Can’t find what you’re looking for? Try using the search bar above.", href: "#", image: null },
  {
    id: 1,
    name: null,
    // description: ["caps and headwear", "Drinkware"],
    description: [
      { name: "caps and headwear", image: "/assets/headwear.png", categories: ["Beanies", "Trucker Caps", "Sun Hats", "Caps", "Bucket Hats"] },
      { name: "Drinkware", image: "/assets/Drinkware.png", categories: ["Glass Drink Bottles", "Plastic Drink Bottles", "Metal Drink Bottles"] },
      {
        name: "Bags",
        image: "/assets/begpack.png",
        categories: ["Tote Bags", "Backpacks & Backsacks", "Bag Tags", "Cooler Bags", "Duffle & Sports Bags", "Laptop Bags", "Paper Bags", "Pouches & Cases", "Satchels", "Wine Bags"],
        // link: "/products",
      },
      {
        name: "Clothing & Apparel",
        image: "/assets/dress.png",
        categories: [
          "T-Shirts Mens",
          "Women's Pants And Shorts",
          "Ties & Scarves",
          "Socks",
          "Singlets & Tanks​",
          "Shirts and Polos",
          "Pants and Shorts",
          "Kids & Youth",
          "Jackets & Vests",
          "Corporate Wear",
          "AS Colour",
          "Aprons",
        ],
      },
    ],
    href: "#",
  },
  { id: 3, name: null, description: "Your customers’ data will be safe and secure", href: "#", categories: ["Beanies", "Trucker Caps", "Sun Hats", "Caps", "Bucket Hats"] },

  {
    id: 4,
    name: null,
    image: "/path-to-images/caps-headwear.png",
    image: "/path-to-images/bags.png",
  },
];

export const shopIndustry = [
  { id: 0, name: "All Industry", description: "Can’t find what you’re looking for? Try using the search bar above.", href: "#", image: null },
  {
    id: 1,
    name: null,
    // description: ["caps and headwear", "Drinkware"],
    description: [
      { name: "Retail", image: "/assets/retail.png", categories: ["Consumer electronics", "Home goods and decor", "Toys and games", " Books and media", "Specialty and niche products"] },
      { name: "Technology", image: "/assets/technology.png", categories: ["Computers and laptops", "Smartphones and tablets", "Wearable technology", "Audio equipment", "Home appliances"] },
      {
        name: "Agricultural",
        image: "/assets/agriculture.png",
        categories: ["Seeds and planting supplies", "Fertilizers and pesticides", "Livestock feed and supplies", "Agricultural chemicals", "Agricultural services"],
      },
      {
        name: "Fashion",
        image: "/assets/fashion.png",
        categories: ["Clothing", " Footwear", "Accessories", "Jewelry", "Handbags"],
      },

      {
        name: "Manufacturing",
        image: "/assets/manufacturing.png",
        categories: [" Raw materials and supplies", "Machinery and equipment"],
      },
    ],
    href: "#",
  },
  { id: 3, name: null, description: "Your customers’ data will be safe and secure", href: "#", categories: ["Beanies", "Trucker Caps", "Sun Hats", "Caps", "Bucket Hats"] },

  {
    id: 4,
    name: null,
    image: "/path-to-images/Sunshine.jpg",
    // image: "/path-to-images/bags.png",
  },
];
export const menuItems = [
  {
    name: "All Products",
    type: "product", // Indicates this is a dropdown menu
    items: products, // Submenu items
    link: "/products",
  },
  { name: "Shop by Industry", link: "/industries", items: shopIndustry, type: "shop" },
  // { name: "Our Portfolio", link: "#" },
  // { name: "Eco Friendly", link: "#" },
];

// [
//   {
//     name: "All Products",
//     type: "popover",
//     link: "/all-products",
//     items: [
//       {
//         name: "Category 1",
//         description: "Explore the best products in Category 1",
//         href: "/category-1",
//         categories: ["Subcategory 1", "Subcategory 2", "Subcategory 3"],
//       },
//       {
//         name: "Category 2",
//         description: "Premium products in Category 2",
//         href: "/category-2",
//       },
//       {
//         name: "Category 3",
//         description: "Browse items from Category 3",
//         href: "/category-3",
//       },
//       {
//         name: "Category 4",
//         description: "Best-selling items in Category 4",
//         href: "/category-4",
//       },
//     ],
//     callsToAction: [
//       {
//         name: "Contact Us",
//         href: "/contact",
//         icon: "EnvelopeIcon",
//       },
//       {
//         name: "Get Support",
//         href: "/support",
//         icon: "SupportIcon",
//       },
//     ],
//   },
//   {
//     name: "Shop by Industry",
//     type: "link",
//     link: "/shop-by-industry",
//   },
//   {
//     name: "Eco-Friendly",
//     type: "link",
//     link: "/eco-friendly",
//   },
// ];
