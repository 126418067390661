 import { configureStore } from "@reduxjs/toolkit";
import { save, load } from "redux-localstorage-simple";
import prodctCartReducer from "./reducer/globalReducer";

const PERSISTED_KEYS = ["rootReducer"];

export const store = configureStore({
  reducer: {
    rootReducer: prodctCartReducer, // Add other reducers if needed
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable warnings for non-serializable actions
    }).concat(typeof window !== "undefined" ? save({ states: PERSISTED_KEYS, debounce: 1000 }) : () => (next) => (action) => next(action)),
  preloadedState:
    typeof window !== "undefined"
      ? load({ states: PERSISTED_KEYS, disableWarnings: true }) // Disable warnings during SSR
      : undefined,
});
