const paths = {
  HOME: "/",
  PRODUCT: "/categories",
  INDUSTRY: "/industries",
  INDUSTRYITEM: "/industries/:slug",
  ALLPRODUCT: "/products",
  TERMCONDITION: "/term-condition",
  ABOUT: "/about",
  CONTACT: "/contact",
  SUBPRODUCT: `/categories/:categoryName`, // Added path for SubProduct this is product page api
  SUBPRODUCTDETAIL: "/products/:categories/:categoryName",
  // SINGLEPRODUCT: "/products/:productId/:categories/:categoryName",
  SINGLEPRODUCT: "/products/:productId",
  CART: "/cart",
};

export default paths;
