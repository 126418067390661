import { lazy } from "react";
import React from "react";
import paths from "./path";
// import ContactPage from "../Pages/Contact";
// Lazy load pages for better performance
const HomePage = lazy(() => import("../Pages/Home"));
const AboutPage = lazy(() => import("../Pages/About"));
const ProductsPage = lazy(() => import("../Pages/Product"));
const SubProduct = lazy(() => import("../Pages/SubProduct"));
const SubProductDetail = lazy(() => import("../Pages/SubProductDetail"));
const SingleProductPage = lazy(() => import("../Pages/SingleProduct"));
const CartPage = lazy(() => import("../Pages/CartPage"));
const IndustryPage = lazy(() => import("../Pages/Industry"));
const SubIndustryDetail = lazy(() => import("../Pages/SubIndustryDetails"));
const AllProduct = lazy(() => import("../Pages/AllProduct"));
const TermCondition = lazy(() => import("../Pages/TermCondition"));
const routes = [
  { path: paths.HOME, element: <HomePage /> },
  { path: paths.PRODUCT, element: <ProductsPage /> },
  { path: paths.ALLPRODUCT, element: <AllProduct /> },
  { path: paths.TERMCONDITION, element: <TermCondition /> },
  { path: paths.ABOUT, element: <AboutPage /> },
  // { path: paths.CONTACT, element: <ContactPage /> },
  { path: paths.SUBPRODUCT, element: <SubProduct /> },
  { path: paths.SUBPRODUCTDETAIL, element: <SubProductDetail /> },
  { path: paths.SINGLEPRODUCT, element: <SingleProductPage /> },
  { path: paths.CART, element: <CartPage /> },

  // industry link
  { path: paths.INDUSTRY, element: <IndustryPage /> },
  { path: paths.INDUSTRYITEM, element: <SubIndustryDetail /> },
];

export default routes;
