import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router";
import useCategories from "../../hooks/useCategories";
import axios from "axios";
import SearchProducts from "../SearchBar";

const SidePanel = ({ onClose }) => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [industriesData, setIndustriesData] = useState([]);

  // Toggle dropdown visibility
  useEffect(() => {
    const fetchIndustriesData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/industries`); // API endpoint to fetch all industries
        setIndustriesData(response.data);
      } catch (error) {
        console.error("Error fetching industries data:", error);
      }
    };

    fetchIndustriesData();
  }, []);

  const { categories, error } = useCategories();
  // Toggles dropdown visibility

  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };
  const navigate = useNavigate();
  const [openSubDropdown, setOpenSubDropdown] = React.useState(null);
  const toggleSubDropdown = (categoryName) => {
    setOpenSubDropdown((prev) => (prev === categoryName ? null : categoryName));
  };
  const closePanel = () => {
    setOpenDropdown(null);
    setOpenSubDropdown(null);
  };
  React.useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".w-96")) {
        closePanel();
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  const handleSubcategoryClick = async (category, subcategory) => {
    await navigate(`/products/${category.replace(/ /g, "-")}/${subcategory.replace(/ /g, "-")}`);
    onClose();
  };

  const handleIndustry = async (industry) => {
    await navigate(`/industries/${industry.replace(/ /g, "-")}`, { state: { industry } });
    onClose();
  };
  return (
    <>
      <div className="w-96 h-screen shadow-lg">
        {/* Menu Items */}

        <div className="flex flex-col">
          <div>
            <div onClick={() => toggleDropdown("allProducts")} className="border-y  border-secondary">
              <button className={`flex justify-between items-center w-full px-4 py-6 text-left ${openDropdown === "products" ? "text-white bg-primary" : "text-black"} hover:bg-primary`}>
                <span className="">All Products</span>
                <span className="text-gray-500">{openDropdown === "allProducts" ? <ChevronUpIcon className="w-4 h-4 text-black" /> : <ChevronDownIcon className="w-4 h-4 text-black" />}</span>
              </button>
            </div>

            {openDropdown === "allProducts" && categories && (
              <div className="bg-gray-50">
                <ul className="pl-2">
                  {categories.map((category, index) => (
                    <li key={index} className="py-5">
                      <div
                        className="flex px-4 justify-between  items-center cursor-pointer hover:text-primary"
                        onClick={() => {
                          toggleSubDropdown(category.category);
                        }}
                      >
                        <Link to={`/categories`}>
                          <span>{category.category}</span>
                        </Link>
                        <span>{openSubDropdown === category.category ? <ChevronUpIcon className="w-4 h-4 text-black" /> : <ChevronDownIcon className="w-4 h-4 text-black" />}</span>
                      </div>

                      {/* Subcategories Dropdown */}
                      {openSubDropdown === category.category && category.subcategories && (
                        <ul className="pl-8 mt-2">
                          {category.subcategories.map((subcategory, subIndex) => (
                            <li key={subIndex} className="py-2 hover:text-primary" onClick={() => handleSubcategoryClick(category.category, subcategory)}>
                              {subcategory}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {/* Shop by Industry */}

          <div className="border-y border-secondary">
            <button
              className={`flex justify-between items-center w-full px-4 py-6 text-left ${openDropdown === "industry" ? "text-white bg-primary" : "text-black"} hover:bg-primary`}
              onClick={() => toggleDropdown("industry")}
            >
              <span>Shop by Industry</span>
              <span className="text-gray-500">{openDropdown === "industry" ? <ChevronUpIcon className="w-4 h-4 text-black" /> : <ChevronDownIcon className="w-4 h-4 text-black" />}</span>
            </button>
            {openDropdown === "industry" && (
              <div className="bg-gray-50">
                <ul className="pl-8">
                  {industriesData?.map((industry) => {
                    return (
                      <li key={industry?.id} className="py-5 hover:text-primary" onClick={() => handleIndustry(industry?.category)}>
                        <>{industry?.category}</>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>

          {/* Static Links */}

          <div className="px-4 py-6 hover:bg-gray-100 border-y border-secondary">
            <span className="text-black hover:text-primary">Contact Us</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidePanel;
