import React from "react";

const LinkedInIcon = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2C6.47708 2 2 6.47708 2 12C2 17.5229 6.47708 22 12 22C17.5229 22 22 17.5229 22 12C22 6.47708 17.5229 2 12 2ZM9.55208 16.1448H7.52708V9.62812H9.55208V16.1448ZM8.52708 8.82812C7.8875 8.82812 7.47396 8.375 7.47396 7.81458C7.47396 7.24271 7.9 6.80312 8.55312 6.80312C9.20625 6.80312 9.60625 7.24271 9.61875 7.81458C9.61875 8.375 9.20625 8.82812 8.52708 8.82812ZM16.9479 16.1448H14.9229V12.5333C14.9229 11.6927 14.6292 11.1219 13.8969 11.1219C13.3375 11.1219 13.0052 11.5083 12.8583 11.8802C12.8042 12.0125 12.7906 12.2 12.7906 12.3865V16.1437H10.7646V11.7063C10.7646 10.8927 10.7385 10.2125 10.7115 9.62708H12.4708L12.5635 10.5323H12.6042C12.8708 10.1073 13.524 9.48021 14.6167 9.48021C15.949 9.48021 16.9479 10.3729 16.9479 12.2917V16.1448Z"
        fill={color}
      />
    </svg>
  );
};

export default LinkedInIcon;
