import React from "react";

const ButtonUI = ({ title, additionalClass, onClick }) => {
  return (
    <button className={`  font-bold py-2 px-4 rounded-full ${additionalClass}`} onClick={onClick}>
      {title}
    </button>
  );
};

export default ButtonUI;
