// Function to generate UUID
const generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// Retrieve UUID from localStorage
const getUUID = () => {
  // Check if we're in the browser (window object is available)
  if (typeof window !== "undefined" && window.localStorage) {
    let uuid = localStorage.getItem("Guest-ID");
    if (!uuid) {
      uuid = generateUUID();
      localStorage.setItem("Guest-ID", uuid);
    }
    return uuid;
  } else {
    // Fallback for SSR or non-browser environments
    return generateUUID(); // Return a new UUID if localStorage is not available
  }
};

// Export functions
export { generateUUID, getUUID };
