import httpService from "./httpService";

// Define product-related API calls
const getCategory = () => httpService.get("/categories");
const getSubCategoryByName = (categoryItem) => httpService.get(`/subcategories/${categoryItem}`);
const getProducts = (categories, subcategories) => httpService.get(`/products-by-category/${categories}/${subcategories}`);
const getProductById = (productId) => httpService.get(`/products/${productId}`);
const getAllProducts = (page) => httpService.get(`/products?page=${page}`);

export default {
  getProducts,
  getCategory,
  getSubCategoryByName,
  getProductById,
  getAllProducts,
};
