export const FooterCategories = [
  { id: 1, name: "Home", link: "/" },
  { id: 2, name: " All Products", link: "/products" },
  { id: 3, name: " Shop by Industry", link: "/industries" },
  { id: 4, name: "Shop by Categories", link: "/categories" },
  // { id: 5, name: "Our Portfolio", link: "#" },
];
 
export const FooterSupport = [
  { id: 1, name: "About Us", link: "/about" },
  // { id: 2, name: "Blogs", link: "#" },
  // { id: 3, name: "Decoration Methods", link: "#" },
  // { id: 4, name: "PMS Colour Chart", link: "#" },
  // { id: 5, name: "FAQs", link: "#" },
  // { id: 6, name: "Contact Us", link: "#" },
  // { id: 7, name: "Privacy Policy", link: "#" },
  { id: 8, name: "Terms and Conditions", link: "/term-condition" },
];

export const FooterContact = [
  { id: 1, name: "hello@promoti.com.au", link: "mailto:hello@promoti.com.au" },
  { id: 2, name: "(+61) 452 508 930" },
  { id: 3, name: "or" },
  { id: 4, name: "(+61) 431 326 550" },
  { id: 5, name: "9am - 5pm (AEST)" },
  { id: 6, name: "ABN 21 673 882 276" },
  { id: 7, name: "Melbourne, Australia" },
];
