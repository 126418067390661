import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const SearchProducts = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [products, setProducts] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const navigate = useNavigate();

  // Debounce function to delay API calls
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  // Fetch products from API
  const fetchProducts = async (query) => {
    if (!query) {
      setProducts([]);
      setModalVisible(false);
      return;
    }
    try {
      setLoading(true);
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/search-products?name=${query}`;
      const response = await axios.get(apiUrl);
      setProducts(response.data || []);
      setModalVisible(true);
    } catch (error) {
      console.error("Error fetching products:", error);
      setProducts([]);
    } finally {
      setLoading(false);
    }
  };

  // Debounced fetch function
  const debouncedFetchProducts = debounce(fetchProducts, 500);

  // Handle input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    debouncedFetchProducts(value);
    setHighlightedIndex(-1); // Reset highlighted index
  };

  // Close modal when clicking outside
  const handleBlur = () => {
    setTimeout(() => setModalVisible(false), 200);
  };

  // Handle key events for navigation and selection
  const handleKeyDown = (e) => {
    if (!isModalVisible) return;

    switch (e.key) {
      case "ArrowDown":
        setHighlightedIndex((prev) => (prev + 1) % products.length);
        break;
      case "ArrowUp":
        setHighlightedIndex((prev) => (prev - 1 + products.length) % products.length);
        break;
      case "Enter":
        if (highlightedIndex >= 0 && products[highlightedIndex]) {
          handleProductClick(products[highlightedIndex].product_name, products[highlightedIndex].id);
        }
        break;
      default:
        break;
    }
  };

  // Handle product selection
  const handleProductClick = (productName, productId) => {
    setSearchQuery("");
    setModalVisible(false);
    navigate(`/products/${productId}`);
  };

  return (
    <div className="relative ">
      {/* Input Field */}
      <input
        type="text"
        placeholder="Search 1000+ products"
        className="pl-10 pr-4 py-2 rounded-full border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
        value={searchQuery}
        onChange={handleInputChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="absolute left-3 top-2.5 w-5 h-5 text-primary">
        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-4.35-4.35m2.1-6.15a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0z" />
      </svg>

      {/* Modal */}
      {isModalVisible && (
        <div className="absolute bg-white shadow-lg rounded-md mt-2 max-h-60 w-full overflow-auto z-50">
          {loading ? (
            <div className="p-4">Loading...</div>
          ) : products.length > 0 ? (
            <ul>
              {products.map((product, index) => (
                <li
                  key={product.id}
                  className={`p-2 hover:bg-tertiary cursor-pointer flex gap-2 ${highlightedIndex === index ? "bg-tertiary" : ""}`}
                  onClick={() => handleProductClick(product.product_name, product.id)}
                  onMouseEnter={() => setHighlightedIndex(index)}
                >
                  {product?.overview_hero_image && <img src={product?.overview_hero_image} alt={product.product_name} className="w-10 h-10" />}
                  {product.product_name}
                </li>
              ))}
            </ul>
          ) : (
            <div className="p-2 text-gray-500">No products found.</div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchProducts;
