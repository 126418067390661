import { createSlice } from "@reduxjs/toolkit";

// Initial state
const initialState = {
  countNumber: 0,
  cartItems: [],
};

const productCartSlice = createSlice({
  name: "productCart",
  initialState,
  reducers: {
    // Set the product cart data and update the total amount
    // addCount(state, { payload }) {
    //   state.countNumber = payload.countNumber;
    // },

    addCount(state, { payload }) {
      const product = payload;

      // Check if the product already exists in the cart
      const existingProductIndex = state.cartItems.findIndex((item) => item.product_id === product.product_id);

      if (existingProductIndex !== -1) {
        // Update the quantity of the existing product
        state.cartItems[existingProductIndex].quantity = product.quantity;
      } else {
        // Add new product to cart
        state.cartItems.push(product);
      }

      // Update the cart count
      state.countNumber = state.cartItems.length;
    },

    removeProduct(state, { payload: productId }) {
      // Filter out the product to remove it
      state.cartItems = state.cartItems.filter((item) => item.product_id !== productId);

      // Update the cart count
      state.countNumber = state.cartItems.length;
    },
    clearCart(state) {
      state.cartItems = []; // Clear all items from the cart
      state.countNumber = 0; // Reset the cart count
    },
  },
});

// Export actions
export const { addCount, clearCart, removeProduct } = productCartSlice.actions;

// Export reducer
export default productCartSlice.reducer;
