import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router";
import { store } from "./store/index";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";


ReactDOM.hydrateRoot(
  document.getElementById('root'),  // Ensure you're targeting the right root element
    <BrowserRouter>
      <Provider store={store}>
        <App />
        <Toaster position="bottom-right" />
      </Provider>
    </BrowserRouter>

);

// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
