import { useState, useEffect } from "react";
import productApi from "../Services/productApi";
import axios from "axios";

const useCategories = () => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await productApi.getCategory(); // Replace with your API URL
        const data = response.data;

        // Process the JSON response to a usable format
        const deepCopyData = JSON.parse(JSON.stringify(data));

        // Process the JSON response to a usable format
        const processedData = Object.keys(deepCopyData).map((key) => {
          return {
            category: deepCopyData[key].category,
            subcategories: Object.values(deepCopyData[key].subcategories), // Convert subcategories object to array
            image: deepCopyData[key].image,
          };
        });
        setCategories(processedData);
      } catch (error) {
        setError("Error fetching categories");
        console.error("Error fetching categories:", error);
      }
      setIsLoading(false);
    };

    fetchCategories();
  }, []);

  return { categories, error, isLoading };
};

export default useCategories;
