import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { match } from "path-to-regexp";

const DynamicTitle = () => {
  const location = useLocation();
  console.log("location:", location);

  useEffect(() => {
    const path = location.pathname;
    const siteTitle = process.env.REACT_APP_SITE_TITLE || "Promoti";

    // const formattedPath = path.startsWith("/")
    //   ? path.slice(1).replaceAll("/", " ") // Remove the leading `/` and replace other `/` with `-`
    //   : path.replaceAll("/", " ");
    const segments = path.split("/").filter(Boolean); // Split path by `/` and remove empty strings
    const formattedPath = segments[segments.length - 1];
    // Map paths to titles
    const titleMap = {
      "/": `${siteTitle} | Home`,
      "/products": `${siteTitle} | Products`,
      "/cart": `${siteTitle} | Cart`,
      "/about": `${siteTitle} | About Us`,
      "/categories": `${siteTitle} | Categories`,
      "/industries": `${siteTitle} | Industries`,
      "/termcondition": `${siteTitle} | Term & Condition`,
    };

    // Check for exact matches
    let newTitle = titleMap[path];

    // Handle dynamic routes
    if (!newTitle) {
      // Match dynamic paths
      const dynamicRoutes = [
        { path: "/categories/:categoryName", title: `${siteTitle} |  ${formattedPath}` },
        { path: "/industries/:industryName", title: `${siteTitle} | ${formattedPath}` },
        { path: "/products/:categories/:categoryName", title: `${siteTitle} | ${formattedPath} ` },
        // { path: "/products/:productId", title:  `${formattedPath} || Promoti` },
      ];

      for (const route of dynamicRoutes) {
        const matchRoute = match(route.path, { decode: decodeURIComponent });
        const matched = matchRoute(path);
        if (matched) {
          newTitle = route.title;
          break;
        }
      }
    }

    // Set default title if no match found
    document.title = newTitle || `${siteTitle}`;
  }, [location]);

  return null; // This component doesn't render anything
};

export default DynamicTitle;
