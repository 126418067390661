import axios from "axios";
import { create } from "apisauce";
// Create an API client instance
const customAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Replace with your backend API URL
  withCredentials: true,

  headers: {
    "access-control-allow-headers": "*",
    "access-control-allow-methods": "*",
    "access-control-allow-origin": "*",
    "cache-control": "no-cache, private",
    "content-type": "application/json",
    vary: "Origin, Accept-Encoding",
    "x-powered-by": "Express",
    supports_credentials: true, // Enable credentials
  },
});

 
const httpService = create({ axiosInstance: customAxiosInstance });

export default httpService;
