import React from "react";

const InstagramIcon = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 8.87531C10.2797 8.87531 8.87577 10.2792 8.87577 11.9995C8.87577 13.7198 10.2797 15.1237 12 15.1237C13.7203 15.1237 15.1242 13.7198 15.1242 11.9995C15.1242 10.2792 13.7203 8.87531 12 8.87531ZM21.3703 11.9995C21.3703 10.7058 21.382 9.42374 21.3094 8.13234C21.2367 6.63234 20.8945 5.30109 19.7976 4.20421C18.6984 3.10499 17.3695 2.76515 15.8695 2.69249C14.5758 2.61984 13.2937 2.63156 12.0023 2.63156C10.7086 2.63156 9.42655 2.61984 8.13515 2.69249C6.63515 2.76515 5.3039 3.10734 4.20702 4.20421C3.1078 5.30343 2.76796 6.63234 2.6953 8.13234C2.62265 9.42609 2.63436 10.7081 2.63436 11.9995C2.63436 13.2909 2.62265 14.5753 2.6953 15.8667C2.76796 17.3667 3.11015 18.698 4.20702 19.7948C5.30624 20.8941 6.63515 21.2339 8.13515 21.3066C9.4289 21.3792 10.7109 21.3675 12.0023 21.3675C13.2961 21.3675 14.5781 21.3792 15.8695 21.3066C17.3695 21.2339 18.7008 20.8917 19.7976 19.7948C20.8969 18.6956 21.2367 17.3667 21.3094 15.8667C21.3844 14.5753 21.3703 13.2933 21.3703 11.9995ZM12 16.8066C9.33983 16.8066 7.19296 14.6597 7.19296 11.9995C7.19296 9.33937 9.33983 7.19249 12 7.19249C14.6601 7.19249 16.807 9.33937 16.807 11.9995C16.807 14.6597 14.6601 16.8066 12 16.8066ZM17.0039 8.11827C16.3828 8.11827 15.8812 7.61671 15.8812 6.99562C15.8812 6.37453 16.3828 5.87296 17.0039 5.87296C17.625 5.87296 18.1266 6.37453 18.1266 6.99562C18.1267 7.1431 18.0978 7.28917 18.0415 7.42546C17.9851 7.56175 17.9024 7.68558 17.7981 7.78987C17.6939 7.89415 17.57 7.97684 17.4337 8.0332C17.2974 8.08955 17.1514 8.11846 17.0039 8.11827Z"
        fill={color}
      />
    </svg>
  );
};

export default InstagramIcon;
