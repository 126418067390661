import "./App.css";
import Routing from "./Router";
import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollToTop from "./Components/UseSelectTop";
function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Routing />
    </div>
  );
}

export default App;
